export default [
  {
    value: 1,
    text: 'Administrador',
  },
  {
    value: 2,
    text: 'Colaborador de campo',

  },
  {
    value: 3,
    text: 'Cliente',
  },
]
