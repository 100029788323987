<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ windowTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="slot-container">
        <slot />
      </div>
      <!-- BODY -->
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'

export default {
  name: 'I2SideBar',
  components: {
    BSidebar,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    windowTitle: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<style scoped>
.slot-container {
  padding: 5px 15px;
}
</style>
