<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="rules"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid':null"
      >
        <b-form-input
          :id="name"
          v-model="inputValue"
          :state="errors.length > 0 ? false:null"
          :type="passwordFieldType"
          :placeholder="placeholder"
          :rules="rules"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min, password,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'I2FormPassword',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    inputValue: '',
    errors: [],
    required,
    confirmed,
    min,
    password,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
    rules(val) {
      this.rules = val
    },
  },
}
</script>

<style scoped>

</style>
