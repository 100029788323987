import { mask } from 'vue-the-mask'

// eslint-disable-next-line import/prefer-default-export
export const applyMask = {
  bind(el, binding) {
    if (binding.value && binding.value !== '') {
      mask(el, binding)
    }
  },
  unbind() {},
}
